import React, { useState, useEffect } from "react";
import Voorblad from "./Voorblad";
import Voorbereiding from "./Voorbereiding";
import Formulieren from "./Formulieren";
import Regeldag from "./Regeldag";
import Week1en2 from "./Week1en2";
import Na2cq3weken from "./Na2cq3weken";
import Vervolg from "./Vervolg";
import Familie from "./Familie";
import Zorg from "./Zorg";
import AccountData from "./AccountData";
import axios from "axios";

function Navigation(props) {
	const id = props.id;
	const [active, setActive] = useState("Voorblad");

	const handleClick = (element) => () => {
		setActive(element.value);
	};

	const VISIBLE_LINKS = {
		Voorblad: (
			<Voorblad
				id={id}
				begeleider={props.mb}
				token={props.token}
				setToken={props.setToken}
			/>
		),
		Voorbereiding: (
			<Voorbereiding id={id} token={props.token} setToken={props.setToken} />
		),
		Formulieren: (
			<Formulieren id={id} token={props.token} setToken={props.setToken} />
		),
		Regeldag: (
			<Regeldag id={id} token={props.token} setToken={props.setToken} />
		),
		Week1en2: (
			<Week1en2 id={id} token={props.token} setToken={props.setToken} />
		),
		Na2cq3weken: (
			<Na2cq3weken id={id} token={props.token} setToken={props.setToken} />
		),
		Vervolg: <Vervolg id={id} token={props.token} setToken={props.setToken} />,
		Familie: <Familie id={id} token={props.token} setToken={props.setToken} />,
		Zorg: <Zorg id={id} token={props.token} setToken={props.setToken} />,
		AccountData: (
			<AccountData id={id} token={props.token} setToken={props.setToken} />
		),
	};

	return (
		<div>
			<div>
				<ul className={styles.bar}>
					{navitems.elements.map((element) => (
						<li
							key={element.id}
							className={
								active === element.value ? styles.itemactive : styles.item
							}
						>
							<button onClick={handleClick(element)} className={styles.styling}>
								{element.name}
							</button>
						</li>
					))}
				</ul>
			</div>
			{VISIBLE_LINKS[active]}
		</div>
	);
}

export default Navigation;

const styles = {
	bar: "flex mt-6 bg-cyan-700 rounded-lg",
	item: "mr-6 p-2",
	itemactive: "mr-6 p-2 bg-green-600 rounded-lg shadow-md",
	styling: "text-white font-bold hover:text-cyan-200",
};

const navitems = {
	elements: [
		{
			id: 1,
			name: "Voorblad",
			value: "Voorblad",
		},
		{
			id: 2,
			name: "Voorbereiding",
			value: "Voorbereiding",
		},
		{
			id: 3,
			name: "Formulieren",
			value: "Formulieren",
		},
		{
			id: 4,
			name: "Regeldag",
			value: "Regeldag",
		},
		{
			id: 5,
			name: "Week 1 & 2",
			value: "Week1en2",
		},
		{
			id: 6,
			name: "Na 2/3 weken",
			value: "Na2cq3weken",
		},
		{
			id: 7,
			name: "Vervolg",
			value: "Vervolg",
		},
		{
			id: 8,
			name: "Zorg",
			value: "Zorg",
		},
		{
			id: 9,
			name: "Accountgegevens",
			value: "AccountData",
		},
	],
};
