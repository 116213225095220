const Styles = {
	bar: "flex mt-6 bg-cyan-700 rounded-lg",
	pagina: "h-screen overflow-auto w-[90%] justify-center p-10",
	container: "container justify-center my-5 bg-gray-100 rounded-lg",
	offset: "ml-5",
	header: "text-2xl font-bold text-green-600",
	docstring: "text-gray-700 hover:text-red-800 whitespace-no-wrap",
	form: "ml-5 flex flex-wrap flex-col py-2 mx-auto bg-gray-100 rounded-lg",
	checklist:
		"grid grid-cols-3 row-auto mt-2 ml-5 py-2 mx-auto bg-gray-100 rounded-lg w-[97%]",
	checklistmulti:
		"grid grid-cols-3 row-auto mt-2 ml-1 py-2 mx-auto bg-gray-100 rounded-lg w-[97%]",
	formmulti:
		"grid grid-cols-2 row-auto mt-2 ml-1 py-2 mx-auto bg-gray-100 rounded-lg w-[97%] ",
	checkselectcompl: "ml-5 w-[35%] mr-5 py-2 mx-auto bg-green-200 text-[10pt]",
	checkselectfirst: "ml-5 w-[35%] mr-5 py-2 mx-auto bg-yellow-200 text-[10pt]",
	checkdate: "ml-5 w-[70%] mr-5 py-2 mx-auto bg-gray-100 text-[10pt]",
	form2col: "grid grid-cols-4 flex flex-wrap flex-col py-2 mx-auto",
	label:
		"m-1 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[50%]",
	label1:
		"inline flex-wrap m-2 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[50%]",
	checkbox:
		"m-2 w-5 h-5 text-cyan-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500",
	button: "m-2 p-2 bg-cyan-600 text-white rounded-lg",
	input:
		"p-1 m-1 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 w-96 outline-0 required:border-red-500 bg-gray-100",
	input1:
		"inline flex-wrap p-1 m-2 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 w-[50%] outline-0 bg-gray-100 required:border-red-500",
	input_care:
		"m-1 font-serif text-[13px] h-[70%] text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 w-[80%] outline-0 required:border-red-500 bg-gray-100",
	column: "flex flex-col",
	columnar: "grid grid-cols-3 gap-4",
	columnhead: "text-l font-bold text-gray-500",
	iconbutton: "text-l bg-cyan-600 text-white rounded-lg",
	table: "min-w-full leading-normal mt-5",
	tablehead:
		"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
	tablelabel: "text-[10pt] font-bold text-gray-500",
	tabledata: "text-[10pt] font-serif text-left text-black mb-2",
	deleteicon: "p-1 text-l bg-red-600 text-white rounded-lg",
	editicon: "ml-1 p-1 text-l bg-yellow-600 text-white rounded-lg",
	tablelines: "border-b border-gray-200 hover:bg-gray-100",
	inputform: "group relative inline-flex py-2 mx-auto bg-gray-100 rounded-lg",
	shotforminput: "inline text-l text-emerald-800 m-2",
};

export default Styles;
