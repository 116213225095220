import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Family_create(props) {
	const styles = {
		submitbutton:
			"border bg-cyan-600 hover:bg-cyan-700 w-40 p-4 my-2 text-white rounded-lg",
		container: "h-screen p-10 w-1/2",
		form: "flex flex-col py-2",
		label: "text-[10pt] text-left py-2 font-medium text-cyan-700",
		input:
			"text-s font-serif text-cyan-900 border-2 border-b-cyan-800 p-1 border-t-0 border-l-0 border-r-0 focus:outline-none",
		header: "text-xl font-bold text-cyan-700",
		table: "min-w-full leading-normal mt-5",
		tableheader:
			"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
		tablelines: "border border-green-600 mt-5",
		tableitem: "border border-green-600 mt-5 text-left",
	};
	const navigate = useNavigate();

	const [familienaam, setFamilienaam] = useState("");
	const [straat, setStraat] = useState("");
	const [huisnummer, setHuisnummer] = useState("");
	const [huistnummertoev, setHuisnummertoev] = useState("");
	const [postcode, setPostcode] = useState("");
	const [plaats, setPlaats] = useState("");
	const [mbdata, setMbdata] = useState([]);
	const [begeleider, setBegeleider] = useState("");

	const senddata = {
		familienaam: familienaam,
		straat: straat,
		huisnummer: huisnummer,
		huistnummertoev: huistnummertoev,
		postcode: postcode,
		plaats: plaats,
		mb_id: begeleider,
	};

	useEffect(() => {
		axios({
			method: "GET",
			url: "/api/mblijst",
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setMbdata(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		axios({
			method: "POST",
			url: "/api/addfamily",
			data: senddata,
			withCredentials: true,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err.res);
				console.log(err.response.status);
				console.log(err.response.headers);
			});
		navigate("/overview");
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>Create Family</div>
			<form className={styles.form} onSubmit={handleSubmit}>
				<label className={styles.label} htmlFor="familienaam">
					Familienaam
				</label>
				<input
					className={styles.input}
					type="text"
					name="familienaam"
					id="familienaam"
					required
					pattern="^[A-Za-z0-9\s]{2,255}$"
					onChange={(e) => setFamilienaam(e.target.value)}
				/>
				<label className={styles.label} htmlFor="straatnaam">
					straatnaam
				</label>
				<input
					className={styles.input}
					type="text"
					name="straatnaam"
					id="straatnaam"
					required="required"
					onChange={(e) => setStraat(e.target.value)}
				/>
				<label className={styles.label} htmlFor="huisnummer">
					huisnummer
				</label>
				<input
					className={styles.input}
					type="number"
					name="huisnummer"
					required="required"
					pattern="^[0-9]{1,5}$"
					id="huisnummer"
					onChange={(e) => setHuisnummer(e.target.value)}
				/>
				<label className={styles.label} htmlFor="huisnummer_toev">
					huisnummer_toev
				</label>
				<input
					className={styles.input}
					type="text"
					name="huisnummer_toev"
					id="huisnummer_toev"
					onChange={(e) => setHuisnummertoev(e.target.value)}
				/>
				<label className={styles.label} htmlFor="postcode">
					postcode
				</label>
				<input
					className={styles.input}
					type="text"
					name="postcode"
					id="postcode"
					required="required"
					pattern="^[0-9]{4}[A-Za-z]{2}$"
					onChange={(e) => setPostcode(e.target.value)}
				/>
				<label className={styles.label} htmlFor="woonplaats">
					woonplaats
				</label>
				<input
					className={styles.input}
					type="text"
					name="woonplaats"
					id="woonplaats"
					required="required"
					onChange={(e) => setPlaats(e.target.value)}
				/>

				<label className={styles.label} htmlFor="mb-er">
					Maatschappelijke begeleider
				</label>
				<select
					className={styles.checkselect}
					name="maatschappelijkbegeleider"
					id="maatschappelijkbegeleider"
					onChange={(e) => setBegeleider(e.target.value)}
				>
					{mbdata.map((mb) => (
						<option value={mb.id}>
							{mb.userfirst} {mb.userlast}
						</option>
					))}
				</select>
				<br />
				<input className={styles.submitbutton} type="submit" value="Submit" />
			</form>
		</div>
	);
}

export default Family_create;
