import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function ShowPicture(props) {
	const [pict, setPicture] = useState(null);
	const person = props.client.id;

	useEffect(() => {
		console.log(props.token);
		axios({
			method: "GET",
			url: "/api/showpicture/" + props.client.id,
			headers: { Authorization: "Bearer " + props.token },
		}).then((res) => {
			setPicture(res.data);
			console.log(res.data);
		});
	}, []);

	return (
		<div>
			{pict ? (
				<img
					className={styles.picture}
					src={`data:image/jpeg;base64,${pict}`}
					alt="test"
				/>
			) : (
				<p>
					<Link
						className="m-2 p-2 bg-cyan-600 text-white rounded-lg"
						to="/addpicture"
						state={{ from: props }}
					>
						Voeg een foto toe
					</Link>
				</p>
			)}
		</div>
	);
}

export default ShowPicture;

const styles = {
	picture: "w-[60pt] mt-[-30pt]",
};
