import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function AddPicture(props) {
	const [postImage, setPostImage] = useState({ myFile: "" });
	const location = useLocation();
	const { from } = location.state;
	const url = "/api/store_picture/" + from.client.id;
	console.log(from.client.id);
	const navigate = useNavigate();
	const createImage = (newImage) => {
		console.log(newImage);
		//axios.post(url, newImage);

		axios({
			method: "POST",
			url: url,
			data: newImage,
			headers: { Authorization: "Bearer " + props.token },
		});

		navigate(-1);
	};

	const createPost = async (post) => {
		try {
			await createImage(post);
		} catch (err) {
			console.log(err.message);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		createPost(postImage);
	};

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		const base64 = await convertToBase64(file);
		setPostImage({ ...postImage, myFile: base64 });
		console.log(base64);
	};

	return (
		<div className={styles.container}>
			<h1 className={styles.header}>Foto van client toevoegen</h1>
			<p className={styles.warning}>
				Let op: het toevoegen van een clientfoto is alleen toegestaan, als de
				client daarmee heeft ingestemd.
			</p>
			<br></br>
			<form className={styles.form} onSubmit={handleSubmit}>
				<label className={styles.label}>Selecteer een foto:</label>
				<input
					onChange={(e) => handleFileUpload(e)}
					type="file"
					id="file"
					name="file"
					label="Image"
					accept=".jpeg, .png, .jpg"
					className={styles.input}
				></input>
				<button className={styles.button} type="submit">
					Upload
				</button>
			</form>
		</div>
	);
}

export default AddPicture;

const styles = {
	container: "h-screen p-10 w-1/2 flex flex-col items-center",
	header: "text-xl font-bold text-cyan-700",
	warning: " text-red-600 font-bold",
	label: "inline text-l font-bold text-cyan-700 m-2",
	input: "inline border-2 border-cyan-700 p-2 w-96 m-2",
	button: "inline bg-cyan-700 text-white p-2 m-2 rounded-md",
	form: "flex flex-col items-center",
};
