const version = {
	frontend: "0.1.2 - production",
	backend: "0.0.8 - production",
	database: "0.0.7 - production",
	database_env: "production",
	author: "Tijmen Baas",
	license: "Authorized for Stichting Vluchtelingenwerkgroep Wijk bij Duurstede",
	valid: "2024-04-30",
	test_completed: "2023-10-29",
	production: "2023-10-29",
	guid: "c3d52f7f-7fc5-41ef-9b41-e3ee07cb40cb",
	something: "BB1F5894-8A2F-49A8-9DBF-AF4DC2420F3C",
};

export default version;
