import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Client_create(props) {
	const styles = {
		pagina: "h-screen overflow-auto w-[90%] justify-center p-10",
		container: "container justify-center my-5 bg-gray-100 rounded-lg",
		part2form: " w-[90%]",
		header: "text-2xl font-bold text-green-600",
		docstring: "text-gray-700 hover:text-red-800 whitespace-no-wrap",
		form: "flex flex-wrap flex-col py-2 mx-auto",
		form2col: "grid grid-cols-4 flex flex-wrap flex-col py-2 mx-auto",
		label:
			"m-2 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[70%]",
		label1:
			"inline flex-wrap m-2 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[70%]",
		checkbox:
			"m-2 w-5 h-5 text-cyan-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500",
		button: "m-2 p-2 bg-cyan-600 text-white rounded-lg",
		input:
			"p-1 m-2 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 w-96 outline-0 required:border-red-500",
		input1:
			"inline flex-wrap p-1 m-2 font-serif w[100pt] text-l text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 outline-0 required:border-red-500",
		column: "flex flex-col",
	};
	const navigate = useNavigate();
	const location = useLocation();
	const { from } = location.state;
	const [created, setCreated] = useState("");
	const [secondCreated, setSecondCreated] = useState("");
	//form data client
	const [voornaam, setVoornaam] = useState("");
	const [tussenvoegsel, setTussenvoegsel] = useState("");
	const [achternaam, setAchternaam] = useState("");
	const [emailadres, setEmailadress] = useState("");
	const [mobiel, setMobiel] = useState("");
	const [partnerMobiel, setPartnerMobiel] = useState("");
	const [heeftZelfstandigdossier, setHeeftZelfstandigdossier] = useState(false);
	const [familiehoofd, setFamiliehoofd] = useState(false);
	//form data personal details
	const [geslacht, setGeslacht] = useState("");
	const [geboortedatum, setGeboortedatum] = useState(new Date());
	const [geboorteplaats, setGeboorteplaats] = useState("");
	const [godsdienst, setGodsdienst] = useState("");
	const [taal, setTaal] = useState("");
	const [in_nederlands_sinds, setIn_nederlands_sinds] = useState(new Date());
	const [in_wbd_sinds, setIn_wbd_sinds] = useState(new Date());
	const [verblijfsstatus, setVerblijfsstatus] = useState("");
	const [verblijfgeldigheid, setVerblijfgeldigheid] = useState(new Date());
	const [person, setPerson] = useState(0);
	const date_added = new Date().toISOString().slice(0, 10);
	const added_by = props.user;
	//url button 1
	const url = "/api/addclient/" + from.id;

	const handleSubmit = (e) => {
		e.preventDefault();
		axios({
			method: "POST",
			url: url,
			data: {
				voornaam: voornaam,
				tussenvoegsel: tussenvoegsel,
				achternaam: achternaam,
				emailadres: emailadres,
				mobiel: mobiel,
				partnerMobiel: partnerMobiel,
				heeftZelfstandigdossier: heeftZelfstandigdossier,
				familiehoofd: familiehoofd,
			},
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				if (res.status == 201) {
					setCreated("disabled");
				}
				console.log(res.data.id_created);
				setPerson(res.data.id_created);
			})
			.catch((err) => {
				console.log(err.res);
				console.log(err.response.status);
				console.log(err.response.headers);
			});

		//navigate("/overview"); //next step is entering client details, should navigate to client details.
	};

	const handleSubmit2 = (e) => {
		e.preventDefault();
		axios({
			method: "POST",
			url: "/api/addpersondetail/" + person,
			data: {
				geslacht: geslacht,
				geboortedatum: geboortedatum,
				geboorteplaats: geboorteplaats,
				godsdienst: godsdienst,
				taal: taal,
				in_nederlands_sinds: in_nederlands_sinds,
				in_wbd_sinds: in_wbd_sinds,
				verblijfsstatus: verblijfsstatus,
				verblijfgeldigheid: verblijfgeldigheid,
				date_added: date_added,
				added_by: added_by,
				person_id: person,
			},
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				if (res.status == 201) {
					setSecondCreated("disabled");
					console.log(res);
					navigate("/familycard", { state: { from } });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const changeGeboortedatum = (date) => {
		setGeboortedatum(date.target.value);
		console.log(geboortedatum);
	};

	const changeIn_nederland_sinds = (date) => {
		setIn_nederlands_sinds(date.target.value);
		console.log(in_nederlands_sinds);
	};

	const changeIn_wbd_sinds = (date) => {
		setIn_wbd_sinds(date.target.value);
		console.log(in_wbd_sinds);
	};

	const changeGeldigheid = (date) => {
		setVerblijfgeldigheid(date.target.value);
		console.log(verblijfgeldigheid);
	};

	return (
		<div className={styles.pagina}>
			<p className={styles.header}>Aanmaken nieuwe persoon {created} </p>
			<p className={styles.docstring}>
				Het formulier aanmaken persoon werkt in twee stappen
				<br />
				<ol className="list-decimal ml-10">
					<li>Voer de persoonsgegevens in</li>
					<li>
						Indien het een familiehoofd betreft, vink de optie familiehoofd aan
					</li>
					<li>
						Indien de persoon 18+ is of daar reden voor is, vink de
						dossierfunctie aan
					</li>
					<li>
						Sla daarna op om het tweede deel van de persoonsgegevens te laden
					</li>
				</ol>
				Om zeker te zijn dat alle gegevens zijn opgeslagen, sla ook de tweede
				keer op.
			</p>

			<div>
				<div className={styles.container}>
					<form onSubmit={handleSubmit}>
						<input
							className={styles.input}
							id="voornaam"
							name="voornaam"
							type="text"
							placeholder="voornaam"
							pattern="^[A-Za-z0-9\s]{3,50}$"
							disabled={created}
							onChange={(e) => setVoornaam(e.target.value)}
						/>
						<input
							className={styles.input}
							id="middelnaam"
							name="middelnaam"
							type="text"
							placeholder="tussenvoegsel"
							disabled={created}
							pattern="^[A-Za-z0-9\s]{1,16}$"
							onChange={(e) => setTussenvoegsel(e.target.value)}
						/>
						<input
							className={styles.input}
							id="achternaam"
							name="achternaam"
							type="text"
							placeholder="achternaam"
							required="required"
							disabled={created}
							pattern="^[A-Za-z0-9\s]{3,100}$"
							onChange={(e) => setAchternaam(e.target.value)}
						/>
						<input
							className={styles.input}
							id="emailadres"
							name="emailadres"
							type="text"
							placeholder="emailadres"
							disabled={created}
							//required="required"
							//pattern="^[A-Za-z0-9]{3,16}$"
							onChange={(e) => setEmailadress(e.target.value)}
						/>
						<input
							className={styles.input}
							id="mobiel"
							name="mobiel"
							type="text"
							placeholder="mobiel geschreven als in 0612345678"
							disabled={created}
							pattern="^[0-9]{10,12}$"
							onChange={(e) => setMobiel(e.target.value)}
						/>
						<input
							className={styles.input}
							id="mobielpartner"
							name="mobielpartner"
							type="text"
							placeholder="mobiel partner"
							disabled={created}
							pattern="^[0-9]{10,12}$"
							onChange={(e) => setPartnerMobiel(e.target.value)}
						/>
						<br />
						<label className={styles.label} htmlFor="familiehoofd">
							Is familiehoofd?
						</label>

						<input
							className={styles.checkbox}
							id="familiehoofd"
							name="familiehoofd"
							type="checkbox"
							placeholder="familiehoofd"
							disabled={created}
							onChange={(e) =>
								e.target.value === "on"
									? setFamiliehoofd(true)
									: setFamiliehoofd(false)
							}
						/>
						<label className={styles.label} htmlFor="familiehoofd">
							Heeft een zelfstandig dossier (creëert een checklist)
						</label>
						<input
							className={styles.checkbox}
							id="zelfstandigdossier"
							name="zelfstandigdossier"
							type="checkbox"
							disabled={created}
							onChange={(e) =>
								e.target.value === "on"
									? setHeeftZelfstandigdossier(true)
									: setHeeftZelfstandigdossier(false)
							}
						/>
						<br></br>
						{created === "" ? (
							<button className={styles.button}>Opslaan </button>
						) : null}
					</form>
					{created === "disabled" ? (
						<div className={styles.part2form}>
							<form className={styles.form2col}>
								<label className={styles.label1}>Geslacht</label>

								<select
									id="geslacht"
									name="geslacht"
									className={styles.input1}
									onChange={(e) => setGeslacht(e.target.value)}
								>
									<option>Selecteer een geslacht</option>
									<option>Man</option>
									<option>Vrouw</option>
									<option>Neutraal</option>
								</select>

								<label className={styles.label1}>Geboortedatum</label>

								<input
									className={styles.input1}
									id="geboortedatum"
									name="geboortedatum"
									type="date"
									value={geboortedatum}
									placeholder="geboortedatum"
									onChange={changeGeboortedatum}
								/>

								<label className={styles.label1}>Geboorteplaats</label>

								<input
									className={styles.input1}
									id="geboorteplaats"
									name="geboorteplaats"
									type="text"
									placeholder="Geboorteplaats"
									pattern="^[a-zA-Z]{3,50}$"
									onChange={(e) => setGeboorteplaats(e.target.value)}
								/>

								<label className={styles.label1}>Godsdienst</label>

								<input
									className={styles.input1}
									id="godsdienst"
									name="godsdienst"
									type="text"
									placeholder="Godsdienst"
									onChange={(e) => setGodsdienst(e.target.value)}
								/>
								<label className={styles.label1}>Moedertaal</label>

								<input
									className={styles.input1}
									id="moedertaal"
									name="moedertaal"
									type="text"
									placeholder="Moedertaal"
									onChange={(e) => setTaal(e.target.value)}
								/>

								<label className={styles.label1}>In Nederland sinds</label>

								<input
									className={styles.input1}
									id="verblijfnld"
									name="verblijfnld"
									type="date"
									placeholder="Verblijft in NL sinds"
									onChange={changeIn_nederland_sinds}
								/>

								<label className={styles.label1}>
									Verblijft in Wijk bij Duurstede sinds
								</label>
								<input
									className={styles.input1}
									id="verblijfwbd"
									name="verblijfwbd"
									type="date"
									placeholder="Verblijft in WbD sinds"
									onChange={changeIn_wbd_sinds}
								/>
								<label className={styles.label1}>Verblijfstatus</label>

								<input
									className={styles.input1}
									id="verblijfstatus"
									name="verblijfstatus"
									type="text"
									placeholder="Verblijfstatus"
									onChange={(e) => setVerblijfsstatus(e.target.value)}
								/>
								<label className={styles.label1}>
									Geldigheid verblijfstatus
								</label>

								<input
									className={styles.input1}
									id="verblijfgeldigheid"
									name="verblijfgeldigheid"
									type="date"
									placeholder="Geldigheid verblijfstatus"
									onChange={changeGeldigheid}
								/>
							</form>
							<button className={styles.button} onClick={handleSubmit2}>
								Opslaan{" "}
							</button>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default Client_create;
