import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEraser,
	faFilePlus,
	faGlasses,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";

function Family_notes(props) {
	const location = useLocation();
	const { from } = location.state;
	const family_id = from.id;
	const navigate = useNavigate();
	const [notes, setNotes] = useState("");
	const [subject, setSubject] = useState("");
	const [data, setData] = useState([]);
	const [persons, setPersons] = useState([]);
	const [deleted, setDeleted] = useState(0);
	const [added, setAdded] = useState(0);
	const [person, setPerson] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [updateId, setUpdateId] = useState({});
	const [search, setSearch] = useState(null);
	const [personfirst, setPersonfirst] = useState(null);

	const url = "/api/family_notes/" + family_id;
	const url_save = "/api/family_notes_save/" + family_id;
	const url_delete = "/api/family_notes_delete/";
	const url_persons = "/api/familymembers/" + family_id;

	const savedata = {
		id: family_id,
		person: person,
		onderwerp: subject,
		notitie: notes,
	};

	function sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	const Modal = (id) => {
		setUpdateId(data.find((note) => note.id === id));
		console.log(updateId);
		setShowModal(true);
	};

	const HandleUpdate = (id) => {
		axios({
			method: "PUT",
			url: "/api/family_notes_update/" + id,
			headers: { Authorization: "Bearer " + props.token },
			data: updateId,
		})
			.then((res) => {
				console.log(res);
				setAdded(added + 1);
				setShowModal(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		setData([]);
		fetchData();
		fetchPersons();
	}, [deleted, added]);

	const fetchData = async () => {
		setLoading(true);
		await axios({
			method: "get",
			url: url,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setData(res.data.notes);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
		sleep(800);
		setLoading(false);
	};

	const fetchPersons = async () => {
		setLoading(true);
		await axios({
			method: "get",
			url: url_persons,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setPersons(res.data);
				setPersonfirst(res.data[0].id);
			})
			.catch((err) => {
				console.log(err);
			});
		sleep(800);
		setLoading(false);
	};

	const loadperson = () => {
		if (person) {
			return person;
		} else {
			setPerson(personfirst);
		}
	};

	const handleSubmit = (e) => {
		loadperson();
		e.preventDefault();

		// if (!person) {
		// 	alert("Selecteer een persoon, om dit formulier te kunnen verzenden.");
		// 	return;

		// } else {
		axios({
			method: "post",
			url: url_save,
			data: savedata,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setAdded(added + 1);
			})
			.then(() => {
				setNotes("");
				setSubject("");
				setPerson(null);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDelete = (deldata) => {
		axios({
			method: "delete",
			url: url_delete + deldata,
			data: deldata,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				if (res) {
					setDeleted(deleted + 1);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className={styles.container}>
			{loading ? (
				<div className={styles.loader}>
					<BounceLoader color={"#7fe37e"} loading={loading} />
				</div>
			) : (
				<div>
					<p className={styles.itemheader}>Notitie toevoegen voor familie</p>

					<div>
						<form className={styles.form} onSubmit={handleSubmit}>
							<label className={styles.label1}>Onderwerp</label>
							<input
								className={styles.input1}
								name="onderwerp"
								id="onderwerp"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
							/>
							<label className={styles.label1}>Persoon/Familielid</label>
							{!person ? (
								<p className={styles.warning}>
									LET OP: Selecteer een persoon... Eerste van de lijst nodig,
									klik twee keer op de toevoegen knop!
								</p>
							) : null}
							<select
								className={styles.label1}
								value={personfirst}
								onChange={(e) => setPerson(e.target.value)}
								required
							>
								{persons.map((item) => (
									<option key={item.id} value={item.id}>
										{item.voornaam}
										{item.tussenvoegsel}
										{item.achternaam}
									</option>
								))}
							</select>
							<label className={styles.label1}>Notitie</label>
							<textarea
								className={styles.input1}
								name="notitie"
								id="notitie"
								value={notes}
								onChange={(e) => setNotes(e.target.value)}
							/>
							<button className={styles.iconbuttonadd}>
								<FontAwesomeIcon icon={faFilePlus} />
							</button>
						</form>
					</div>
					<div>
						<div className={styles.divider}>Notities reeds aangemaakt</div>
						<input
							placeholder="zoeken op onderwerp"
							className={styles.searchfield}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<div className={styles.tablecontainer}>
							<table className={styles.table}>
								<thead>
									<tr>
										<th className={styles.tableheader}>Datum</th>
										<th className={styles.tableheader}>Persoon</th>
										<th className={styles.tableheader}>Onderwerp</th>
										<th className={styles.tableheader}>Notitie</th>
										<th className={styles.tableheader}>Acties</th>
									</tr>
								</thead>
								<tbody>
									{!search
										? data.map((item) => (
												<tr key={item.id}>
													<td className={styles.tableitem}>{item.date}</td>
													<td className={styles.tableitem}>
														{item.voornaam} {item.achternaam}
													</td>
													<td className={styles.tableitem}>{item.subject}</td>
													<td className={styles.tableitem}>
														{item.note.substring(0, 255)} ...{" "}
														<strong>[klik op bekijken]</strong>
													</td>
													<td className={styles.tableitem}>
														<button
															className={styles.iconbuttonread}
															onClick={() => Modal(item.id)}
														>
															<FontAwesomeIcon icon={faGlasses} />
														</button>
														<button
															className={styles.iconbuttondelete}
															onClick={() => handleDelete(item.id)}
														>
															<FontAwesomeIcon icon={faEraser} />
														</button>
													</td>
												</tr>
										  ))
										: data
												.filter((subj) =>
													subj.subject
														.toLowerCase()
														.includes(search.toLowerCase())
												)
												.map((item) => (
													<tr key={item.id}>
														<td className={styles.tableitem}>{item.date}</td>
														<td className={styles.tableitem}>
															{item.voornaam} {item.achternaam}
														</td>
														<td className={styles.tableitem}>{item.subject}</td>
														<td className={styles.tableitem}>
															{item.note.substring(0, 255)} ...{" "}
															<strong>[klik op bekijken]</strong>
														</td>
														<td className={styles.tableitem}>
															<button
																className={styles.iconbuttonread}
																onClick={() => Modal(item.id)}
															>
																<FontAwesomeIcon icon={faGlasses} />
															</button>
															<button
																className={styles.iconbuttondelete}
																onClick={() => handleDelete(item.id)}
															>
																<FontAwesomeIcon icon={faEraser} />
															</button>
														</td>
													</tr>
												))}
								</tbody>
							</table>
						</div>
						<div>
							<div></div>
						</div>
					</div>
				</div>
			)}
			{showModal ? (
				<>
					<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-[50%] my-6 mx-auto max-w-3xl">
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-blue-100 outline-none focus:outline-none">
								<div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
									<h3 className="text-2xl font=semibold">
										Notitie bekijken en onderwerp en notitie updaten
									</h3>
									<br />

									<button
										className="bg-transparent border-0 text-black float-right"
										onClick={() => setShowModal(false)}
									>
										<span className="text-white opacity-7 h-6 w-6 text-xl block bg-red-800 py-0 rounded-full">
											-
										</span>
									</button>
								</div>
								<div className="relative p-6 flex-auto">
									<p>
										<strong>Notitie voor:</strong> {updateId.voornaam}{" "}
										{updateId.achternaam}
									</p>

									<p>
										<strong>Datum aangemaakt:</strong> {updateId.date}
									</p>
									<form className="bg-yellow-500 shadow-md rounded px-8 pt-6 pb-8 w-full">
										<label className="block text-black text-sm font-bold mb-1">
											Onderwerp
										</label>
										<input
											value={updateId.subject}
											onChange={(e) => {
												setUpdateId((previousValue) => ({
													...previousValue,
													subject: e.target.value,
												}));
											}}
											className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
										/>
										<label className="block text-black text-sm font-bold mb-1">
											Notitie
										</label>
										<textarea
											value={updateId.note}
											onChange={(e) => {
												setUpdateId((previousValue) => ({
													...previousValue,
													note: e.target.value,
												}));
											}}
											className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
										/>
									</form>
								</div>
								<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
										type="button"
										onClick={() => setShowModal(false)}
									>
										Annuleren
									</button>
									<button
										className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
										type="button"
										onClick={() => HandleUpdate(updateId.id)}
									>
										Verzenden
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</div>
	);
}

export default Family_notes;

const styles = {
	container: "container h-screen w-[90%] overflow-auto",
	divider:
		"border-b-2 border-t-2 border-t-cyan-800 border-b-cyan-800 font-serif text-xl text-gray-700 font-bold ml-5 mt-5",
	table: "min-w-full leading-normal mt-5",
	tableheader:
		"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
	tablelines: "border-t-green-600 border-b-green-600 mt-5",
	tableitem:
		"border-t-2 border-b-2 border-t-green-600 border-b-green-600 py-1 text-left text-sm text-gray-700",
	tablecontainer: "ml-5",
	form: "ml-5 flex flex-wrap py-2 mx-auto bg-gray-100 rounded-lg",
	checklist:
		"grid grid-cols-3 row-auto mt-2 ml-5 py-2 mx-auto bg-gray-100 rounded-lg w-[97%]",
	checklistmulti:
		"grid grid-cols-3 row-auto mt-2 ml-1 py-2 mx-auto bg-gray-100 rounded-lg w-[97%]",
	checkselectcompl: "ml-5 w-[35%] mr-5 py-2 mx-auto bg-green-200 text-[10pt]",
	checkselectfirst: "ml-5 w-[35%] mr-5 py-2 mx-auto bg-yellow-200 text-[10pt]",
	checkdate: "ml-5 w-[70%] mr-5 py-2 mx-auto bg-gray-100 text-[10pt]",
	form2col: "grid grid-cols-4 flex flex-wrap flex-col py-2 mx-auto",
	label:
		"m-1 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[50%]",
	label1:
		"inline flex-wrap ml-2 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[50%]",
	checkbox:
		"m-2 w-5 h-5 text-cyan-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500",
	button: "m-2 p-2 bg-cyan-600 text-white",
	input:
		"p-1 ml-2 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-t-teal-700 bg-teal-500 border-solid border-2 border-t-2 border-l-0 border-r-0 mr-10 w-96 outline-0 required:border-red-500 bg-gray-100",
	input1:
		"inline flex-wrap p-1 m-2 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-t-b-teal-700 border-solid border-2 border-t-1 bg-cyan-200 border-l-0 border-r-0 mr-10 w-[50%] outline-0 bg-gray-100 required:border-red-500",
	column: "flex flex-col",
	columnar: "grid grid-cols-3 gap-4",
	columnhead: "text-l font-bold text-gray-500",
	iconbuttonread:
		"text-l bg-cyan-700 text-white content-center p-2 font-bold ml-1 mr-1",
	iconbuttondelete: "text-l bg-red-700 text-white content-center p-2 font-bold",
	iconbuttonadd:
		"text-[24pt] text-green-900 content-center font-bold rounded-full bg-green-200 p-1 ml-2 w-24 ",
	warning: "inline text-red-600 font-bold text-xs ml-2 bg-yellow-200",
	itemheader: "ml-5 mt-4 text-xl font-bold text-gray-500",
	loader: "flex justify-center items-center h-screen",
	searchfield:
		"w-96 ml-5 mt-4 text-l text-gray-500 border-2 border-gray-500 p-2",
};
