import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SideImage = require("../Assets/Pictures/family-in-the-mountains-2021-12-09-21-49-08-utc.jpg");

function FamilyOverview(props) {
	const styles = {
		flex: "flex flex-row w-full",
		table: "min-w-full leading-normal mt-5",
		tableheader:
			"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
		tablelines: "border border-green-600 mt-5",
		tableitem: "border border-green-600 mt-5 text-left",
		container: "basis-3/4 justify-center h-screen p-10",
		sidepicture: "basis-1/4 justify-center h-screen",
		image: "w-full h-full object-cover",
		header: "text-2xl font-bold text-green-600",
		tabledata:
			"px-2 py-2 border-b border-gray-200 bg-white text-sm bg-gray-100 hover:bg-gray-200",
		detail: "font-serif text-gray-700 hover:text-red-800 whitespace-no-wrap",
		button:
			"text-[10pt] border bg-green-600 hover:bg-green-700 w-full h-full text-white rounded-lg",
	};
	const [family, setFamily] = useState([]);
	const navigate = useNavigate();

	const handleDoubleClick = (fam) => {
		navigate("/familycard/", { state: { from: fam } });
	};

	useEffect(() => {
		axios({
			method: "GET",
			url: "/api/familylist",
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setFamily(res.data);
			})
			.catch((err) => {
				console.log(err);
				if (err.response.status === 401) {
					axios({
						method: "POST",
						url: "/api/logoutreact",
					});
					navigate("/login");
				}
			});
	}, []);

	console.log(family);

	return (
		<div className={styles.flex}>
			<div className={styles.container}>
				<div className={styles.header}>
					Overzicht clienten per familie(hoofd)
				</div>

				<div>
					<table className={styles.table}>
						<thead className={styles.tableheader}>
							<tr>
								<th>Client-id</th>
								<th>Familienaam</th>
								<th>Straat en huisnummer</th>
								<th>Postcode</th>
								<th>Woonplaats</th>
							</tr>
						</thead>
						<tbody className={styles.tablelines}>
							{family.map((fam) => (
								<tr className={styles.tableitem} key={fam.id}>
									<td className={styles.tabledata}>
										<p className={styles.detail}>{fam.id}</p>
									</td>
									<td className={styles.tabledata}>
										<p
											className={styles.detail}
											onDoubleClick={() => handleDoubleClick(fam)}
										>
											{fam.familienaam}
										</p>
									</td>
									<td className={styles.tabledata}>
										<p
											className={styles.detail}
											onDoubleClick={() => handleDoubleClick(fam)}
										>
											{fam.straat} {fam.huisnummer} {fam.huisnummer_toev}
										</p>
									</td>
									<td
										className={styles.tabledata}
										onDoubleClick={() => handleDoubleClick(fam)}
									>
										<p className={styles.detail}>{fam.postcode}</p>
									</td>
									<td
										className={styles.tabledata}
										onDoubleClick={() => handleDoubleClick(fam)}
									>
										<p className={styles.detail}>{fam.woonplaats}</p>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className={styles.sidepicture}>
				<img className={styles.image} src={SideImage} />
			</div>
		</div>
	);
}

export default FamilyOverview;
