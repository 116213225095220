import React, { useReducer } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Requests(props) {
	const [requestData, setRequestData] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			verzoektitel: "",
			verzoekomschrijving: "",
			verzoektype: "selecteer",
			verzoekpagina: "",
			verzoekbeschrijving: "",
		}
	);

	const navigate = useNavigate();

	const handleSubmit = (e) => {
		if (requestData.verzoektype === "selecteer") {
			alert("Selecteer een verzoektype");
		} else {
			axios({
				method: "post",
				url: "/api/request-post",
				data: {
					verzoektitel: requestData.verzoektitel,
					verzoekomschrijving: requestData.verzoekomschrijving,
					verzoektype: requestData.verzoektype,
					verzoekpagina: requestData.verzoekpagina,
					verzoekbeschrijving: requestData.verzoekbeschrijving,
				},
				headers: { Authorization: "Bearer " + props.token },
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});

			navigate("/overview");
		}
	};

	const handleOnChange = (e) => {
		const { name, value } = e.target;
		setRequestData({ [name]: value });
		console.log(requestData);
	};

	return (
		<div className={styles.pagina}>
			<div className={styles.container}>
				<h1 className={styles.header}>Requests</h1>
				<p className={styles.docstring}>
					Je kunt hier requests toevoegen voor nieuwe functies in de applicatie,
					of wijzigingen in de applicatie. Gebruik deze functie
				</p>
				<p className={styles.docstring}>
					alleen om kleine wijzigingen aan te vragen. Grote wijzigingen worden
					via gebruikersoverleg in beeld gebracht.
				</p>
				<div className="mt-10">
					<div>
						<form className={styles.form}>
							<label className={styles.label1}>Verzoektitel</label>
							<input
								required
								name="verzoektitel"
								onChange={handleOnChange}
								className={styles.input1}
							/>
						</form>
						<form className={styles.form}>
							<label className={styles.label1}>
								Verzoek korte omschrijving
							</label>
							<input
								className={styles.input1}
								name="verzoekomschrijving"
								onChange={handleOnChange}
							/>

							<label className={styles.label1}>Type verzoek</label>
							<select
								className={styles.input1}
								name="verzoektype"
								onChange={handleOnChange}
							>
								{" "}
								<option value="selecteer">Selecteer</option>
								<option value="nieuwe functie">Nieuwe functie</option>
								<option value="aanpassing functie">Aanpassing functie</option>
								<option value="bug">Bug</option>
								<option value="other">Anders</option>
							</select>
							<label className={styles.label1}>
								Voor welke pagina of welk formulier wil je een verzoek indienen?
							</label>
							<input
								className={styles.input1}
								onChange={handleOnChange}
								name="verzoekpagina"
							/>
							<label className={styles.label1}>
								Geef een gedetailleerde beschrijving van het verzoek met (1)
								huidige situatie (2) wat het probleem is (3) hoe het verzoek dit
								probleem oplost (4) voordelen (5) meerdere gebruikers?
							</label>
							<textarea
								className={styles.input1}
								name="verzoekbeschrijving"
								onChange={handleOnChange}
							/>
						</form>
						<button onClick={handleSubmit} className={styles.button}>
							Verzoek indienen
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Requests;

const styles = {
	pagina: "h-screen overflow-auto w-[90%] justify-center p-10",
	container: "container justify-center my-5 bg-gray-200 rounded-lg",
	part2form: " w-[90%]",
	header: "text-2xl font-bold text-green-600",
	docstring: "text-gray-700 hover:text-red-800 whitespace-no-wrap",
	form: "flex flex-wrap flex-col py-2 mx-auto",
	form2col: "grid grid-cols-4 flex flex-wrap flex-col py-2 mx-auto",
	label:
		"m-1 text-[10pt] text-left py-1 font-medium text-cyan-900 outline-none w-[70%]",
	label1:
		"inline flex-wrap m-1 text-[10pt] text-left py-2 font-medium text-cyan-900 outline-none w-[70%]",
	checkbox:
		"m-2 w-5 h-5 text-cyan-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500",
	button: "m-2 p-2 bg-cyan-600 text-white rounded-lg",
	input:
		"p-1 m-1 font-serif text-l text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 w-96 outline-0 required:border-red-500",
	input1:
		"inline flex-wrap p-1 m-1 font-serif w[100pt] text-l text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 outline-0 required:border-red-500",
	column: "flex flex-col",
};
