import React, { useEffect, useState } from "react";
import Styles from "./Styles.jsx";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileEdit,
	faInfoSquare,
	faPaperPlane,
	faTrashCanXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { ClockLoader } from "react-spinners";

const styles = Styles;
function AccountData(props) {
	const [accountlist, setAccountlist] = useState([]);
	const [isloading, setLoading] = useState(true);

	//add a new account (we use a bit of ineffient way to get the data, but it works)
	const [accountprovider, setAccountprovider] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [url, setUrl] = useState("");
	const [notes, setNotes] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [updateId, setUpdateId] = useState([]);

	const Modal = (id) => {
		setUpdateId(accountlist.find((account) => account.id === id));
		console.log(updateId);
		setShowModal(true);
	};

	function sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	const getAccounts = () => {
		setLoading(true);
		sleep(1000);
		axios({
			method: "GET",
			url: "/api/accountinfo/" + props.id,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				console.log(res.data);
				setAccountlist(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setAccountlist([]);
				setLoading(false);
			});
	};

	useEffect(() => {
		getAccounts();
	}, []);

	const data = {
		accountprovider: accountprovider,
		username: username,
		password: password,
		userid: props.id,
		url: url,
		notes: notes,
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		axios({
			method: "POST",
			url: "/api/postaccountdata/" + props.id,
			headers: { Authorization: "Bearer " + props.token },
			data: data,
		})
			.then((res) => {
				console.log(res);
				getAccounts();
				setAccountprovider("");
				setUsername("");
				setPassword("");
				setUrl("");
				setNotes("");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const HandleUpdate = (id) => {
		axios({
			method: "PUT",
			url: "/api/updateaccountdata/" + id,
			headers: { Authorization: "Bearer " + props.token },
			data: updateId,
		})
			.then((res) => {
				console.log(res);
				getAccounts();
				setShowModal(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDelete = (id) => {
		axios({
			method: "DELETE",
			url: "/api/deleteaccountdata/" + id,
			headers: { Authorization: "Bearer " + props.token },
			data: id,
		})
			.then((res) => {
				console.log(res);
				getAccounts();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<div>
				<div>
					{isloading ? (
						<ClockLoader color={"#7fe37e"} loading={isloading} size={70} />
					) : null}
				</div>
				<div className={styles.columnhead}>Accountgegevens</div>
				<div>
					{accountlist ? (
						<table className={styles.table}>
							<thead className={styles.tablehead}>
								<tr>
									<th className={styles.tablelabel}>Accountprovider</th>
									<th className={styles.tablelabel}>Gebruikersnaam</th>
									<th className={styles.tablelabel}>Wachtwoord</th>
									<th className={styles.tablelabel}>Url</th>
									<th className={styles.tablelabel}>Notities</th>
									<th className={styles.tablelabel}>Acties</th>
								</tr>
							</thead>
							<tbody className={styles.tablelines}>
								{accountlist.map((accountinfo) => (
									<tr key={accountinfo.id}>
										<td className={styles.tabledata}>
											{accountinfo.accountprovider}
										</td>
										<td className={styles.tabledata}>{accountinfo.username}</td>
										<td className={styles.tabledata}>{accountinfo.password}</td>
										<td className={styles.tabledata}>{accountinfo.url}</td>
										<td className={styles.tabledata}>{accountinfo.notities}</td>
										<td className={styles.tabledata}>
											<button
												onClick={() => {
													const confirmbox = window.confirm(
														"Weet je zeker dat je dit account wilt verwijderen?"
													);
													if (confirmbox === true) {
														handleDelete(accountinfo.id);
													}
												}}
											>
												<FontAwesomeIcon
													className={styles.deleteicon}
													icon={faTrashCanXmark}
												/>
											</button>
											<button
												onClick={() => {
													Modal(accountinfo.id);
												}}
											>
												<FontAwesomeIcon
													icon={faFileEdit}
													className={styles.editicon}
												/>
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<div>
							Geen accounts gevonden, voeg onderstaande een nieuwe account toe
						</div>
					)}
				</div>
				<br></br>
				<br></br>
				<div className={styles.columnhead}>Account toevoegen</div>
				<div className={styles.inputform}>
					<form onSubmit={handleSubmit}>
						<input
							className={styles.shotforminput}
							onChange={(e) => setAccountprovider(e.target.value)}
							type="text"
							placeholder="Accountprovider"
							value={accountprovider}
						/>
						<div className="group inline cursor-pointer p-2">
							<FontAwesomeIcon icon={faInfoSquare} />
							<div className="absolute invisible bottom-7 group-hover:visible w-40 bg-emerald-200 text-black px-4 ml-8 mb-10 p-2 text-sm rounded-md">
								<span leading-2 text-gray-600 pt-2 pb-2>
									Dit kan bijvoorbeeld zijn: DigID, mijnKPN, kortom de naam van
									de partij waarvoor het account wordt vastgelegd.
								</span>
							</div>
						</div>

						<input
							className={styles.shotforminput}
							onChange={(e) => setUsername(e.target.value)}
							type="text"
							placeholder="Gebruikersnaam"
							value={username}
						/>
						<input
							className={styles.shotforminput}
							onChange={(e) => setPassword(e.target.value)}
							type="text"
							placeholder="Wachtwoord"
							value={password}
						/>
						<input
							onChange={(e) => setUrl(e.target.value)}
							type="text"
							placeholder="Url"
							value={url}
						/>
						<input
							className={styles.shotforminput}
							onChange={(e) => setNotes(e.target.value)}
							type="text"
							placeholder="Notities"
							value={notes}
						/>
						<button className={styles.button} type="submit">
							<FontAwesomeIcon icon={faPaperPlane} />
						</button>
					</form>
				</div>
				{showModal ? (
					<>
						<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
							<div className="relative w-[50%] my-6 mx-auto max-w-3xl">
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
									<div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
										<h3 className="text-2xl font=semibold">
											Accountinfo updaten
										</h3>
										<br />

										<button
											className="bg-transparent border-0 text-black float-right"
											onClick={() => setShowModal(false)}
										>
											<span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full">
												x
											</span>
										</button>
									</div>
									<div className="relative p-6 flex-auto">
										<form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
											<label className="block text-black text-sm font-bold mb-1">
												Accountprovider
											</label>
											<input
												value={updateId.accountprovider}
												onChange={(e) => {
													setUpdateId((previousValue) => ({
														...previousValue,
														accountprovider: e.target.value,
													}));
												}}
												className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
											/>
											<label className="block text-black text-sm font-bold mb-1">
												Accountname
											</label>
											<input
												value={updateId.username}
												onChange={(e) => {
													setUpdateId((previousValue) => ({
														...previousValue,
														username: e.target.value,
													}));
												}}
												className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
											/>
											<label className="block text-black text-sm font-bold mb-1">
												Password
											</label>
											<input
												value={updateId.password}
												onChange={(e) => {
													setUpdateId((previousValue) => ({
														...previousValue,
														password: e.target.value,
													}));
												}}
												className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
											/>
											<label className="block text-black text-sm font-bold mb-1">
												URL
											</label>
											<input
												value={updateId.url}
												onChange={(e) => {
													setUpdateId((previousValue) => ({
														...previousValue,
														url: e.target.value,
													}));
												}}
												className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
											/>
											<label className="block text-black text-sm font-bold mb-1">
												Notities
											</label>
											<input
												value={updateId.notities}
												onChange={(e) => {
													setUpdateId((previousValue) => ({
														...previousValue,
														notities: e.target.value,
													}));
												}}
												className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
											/>
										</form>
									</div>
									<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
										<button
											className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
											type="button"
											onClick={() => setShowModal(false)}
										>
											Annuleren
										</button>
										<button
											className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
											type="button"
											onClick={() => HandleUpdate(updateId.id)}
										>
											Verzenden
										</button>
									</div>
								</div>
							</div>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
}

export default AccountData;
