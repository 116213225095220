import React from "react";
import version from "../Utilities/version.js";

function Version() {
	return (
		<div className={styles.container}>
			<div>
				<h1 className={styles.header}>Releaseinformatie</h1>
				<p>
					<strong>Versienummer gebruikersinterface :</strong> {version.frontend}
				</p>
				<p>
					<strong>Versienummer backend server : </strong>
					{version.backend}
				</p>
				<p>
					<strong>Versienummer database : </strong>
					{version.database}
				</p>
				<p>
					<strong>Database omgeving: </strong>
					{version.database_env}{" "}
					{version.database_env === "development" ? (
						<p className={styles.warning}>DO NOT USE WITH REAL DATA</p>
					) : (
						""
					)}{" "}
				</p>
				<p>
					<strong>License GUID:</strong>
					{version.guid}
				</p>
			</div>
		</div>
	);
}

export default Version;

const styles = {
	container: "h-screen p-10 w-1/2",
	header: "text-xl font-bold text-cyan-700",
	warning: " text-red-600 font-bold",
};
