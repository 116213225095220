import { Routes, Route } from "react-router-dom";
import FamilyOverview from "./Pages/family_overview.jsx";
import Login from "./Pages/Login.jsx";
import Navbar from "./Components/Navbar.jsx";
import Header from "./Components/Header.jsx";
import Profile from "./Pages/Profile.jsx";
import Family_create from "./Pages/Family_create.jsx";
import Family_update from "./Pages/Family_update.jsx";
import Family_card from "./Pages/Family_card.jsx";
import Sidemenu from "./Components/Sidemenu.jsx";
import Client_create from "./Pages/Client_create";
import Client_initial from "./Pages/Client_initial";
import Client_list from "./Pages/Client_list";
import Family_notes from "./Pages/Family_notes";
import Spoorboekje from "./Spoorboekje/Spoorboekje.jsx";
import Mijn_clienten from "./Pages/Mijn_clienten.jsx";
import Version from "./Pages/Version.jsx";
import useToken from "./Utilities/useToken";
import AddPicture from "./Components/AddPicture.jsx";
import ShowPicture from "./Components/ShowPicture.jsx";
import Personalia from "./Spoorboekje/Personalia.jsx";
import Requests from "./Pages/Requests.jsx";

function App() {
	const { token, user, removeToken, setToken, setUser } = useToken();
	return (
		<div>
			<Header token={removeToken} active={token} user={user} />
			{!token && token !== "" && token !== undefined ? (
				<Login setToken={setToken} setUser={setUser} />
			) : (
				<>
					<div className="flex">
						<Sidemenu />
						<Routes>
							<Route
								exact
								path="/addpicture"
								element={<AddPicture token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/personalia"
								element={<Personalia token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/showpicture"
								element={<ShowPicture token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/overview"
								element={<FamilyOverview token={token} setToken={setToken} />}
							/>
							<Route exact path="/profile" element={<Profile />} />
							<Route
								exact
								path="/familycreate"
								element={<Family_create token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/clientcreate"
								element={<Client_create token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/clientinitial"
								element={<Client_initial token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/clientlist"
								element={<Client_list token={token} setToken={setToken} />}
							/>

							<Route
								exact
								path="/familycard"
								element={<Family_card token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/familyupdate"
								element={<Family_update token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/familynotes"
								element={<Family_notes token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/spoorboekje"
								element={<Spoorboekje token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/mijn_clienten"
								element={<Mijn_clienten token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/versie"
								element={<Version token={token} setToken={setToken} />}
							/>
							<Route
								exact
								path="/requests"
								element={<Requests token={token} setToken={setToken} />}
							/>
						</Routes>
					</div>
				</>
			)}
		</div>
	);
}

export default App;
