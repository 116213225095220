import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader, faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useLocation } from "react-router-dom";

function Personalia(props) {
	const location = useLocation();
	const { from } = location.state;
	const person_id = from.id;

	const [master, setMaster] = useState("");
	const [personalia, setPersonalia] = useState("");

	const [editingPerson, setEditingPerson] = useState(false);
	const [editingDetails, setEditingDetails] = useState(false);
	const [backgroundMaster, setBackgroundMaster] = useState("bg-emerald-100");
	const [backgroundDetails, setBackgroundDetails] = useState("bg-emerald-100");
	const [backgroundButton, setBackgroundButton] = useState("bg-cyan-600");
	const styles = {
		container: `flex flex-col w-screen h-screen p-6`,
		header: `text-2xl font-bold text-green-600`,
		master: `${backgroundMaster}`,
		details: `${backgroundDetails}`,
		button: `m-2 p-2 ${backgroundButton} text-white rounded-lg`,
		form: `w-1/3 ml-5 grid grid-cols-2 py-2 mx-auto rounded-lg ${backgroundMaster}`,
		label: " text-[8pt] text-left font-medium text-black",
		input: ` ${backgroundMaster} font-serif text-[11pt] text-emerald-800 font-medium border-b-teal-700 border-solid border-2 border-t-0 border-l-0 border-r-0 mr-10 w-96 outline-0 required:border-red-500 `,
	};

	useEffect(() => {
		axios({
			method: "GET",
			url: "/api/personalia/" + person_id,
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setMaster(res.data.master);
				setPersonalia(res.data.personalia);

				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleEditDetails = () => {
		setEditingDetails(true);
		setBackgroundDetails("bg-orange-100");
	};

	const handleEditPersoon = () => {
		setEditingPerson(true);
		setBackgroundMaster("bg-orange-100");
	};

	const handleStopDetailsEdit = () => {
		setEditingDetails(false);
		setBackgroundDetails("bg-emerald-100");
	};

	const handleStopPersoonEdit = () => {
		const cancel = window.confirm(
			"Weet je zeker dat je stop, wijzigingen worden niet opgeslagen"
		);
		if (cancel) {
			setEditingPerson(false);
			setBackgroundMaster("bg-emerald-100");
		} else {
			return;
		}
	};

	const savePersonChanges = () => {
		const confirm = window.confirm(
			"Weet je zeker dat je deze wijzigingen wilt doorvoeren?"
		);
		if (confirm) {
			axios({
				method: "PUT",
				url: "/api/update_master/" + person_id,
				data: master,
				headers: { Authorization: "Bearer " + props.token },
			})
				.then((res) => {
					setEditingPerson(false);
					setBackgroundMaster("bg-emerald-100");
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			return;
		}
	};

	const saveDetailsChanges = () => {
		const confirm = window.confirm(
			"Weet je zeker dat je deze wijzigingen wilt doorvoeren?"
		);
		if (confirm) {
			axios({
				method: "PUT",
				url: "/api/update_details/" + person_id,
				data: personalia,
				headers: { Authorization: "Bearer " + props.token },
			})
				.then((res) => {
					setEditingDetails(false);
					setBackgroundDetails("bg-emerald-100");
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			return;
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.master}>
				<div>
					{editingPerson || editingDetails ? (
						<div
							className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
							role="alert"
						>
							<strong className="font-bold">Waarschuwing! </strong>
							<span className="block sm:inline">
								Je bent nu persoonsgegevens aan het bewerken, weet je zeker dat
								dit nodig is? <FontAwesomeIcon icon={faEdit} />
							</span>
						</div>
					) : (
						<div>
							<FontAwesomeIcon icon={faBookReader} />
						</div>
					)}
				</div>
				<div>
					<p className={styles.header}>Persoonsgegevens</p>
					<form className={styles.form}>
						<label className={styles.label}>Voornaam</label>
						<input
							className={styles.input}
							onChange={(e) => {
								setMaster({ ...master, voornaam: e.target.value });
							}}
							type="text"
							disabled={!editingPerson}
							value={master.voornaam}
						/>
						<label className={styles.label}>tussenvoegsel</label>
						<input
							className={styles.input}
							onChange={(e) => {
								setMaster({ ...master, middelnaam: e.target.value });
							}}
							type="text"
							disabled={!editingPerson}
							value={master.middelnaam}
						/>
						<label className={styles.label}>Achternaam</label>
						<input
							className={styles.input}
							onChange={(e) => {
								setMaster({ ...master, achternaam: e.target.value });
							}}
							type="text"
							disabled={!editingPerson}
							value={master.achternaam}
						/>
						<label className={styles.label}>Telefoonnummer</label>
						<input
							className={styles.input}
							onChange={(e) => {
								setMaster({ ...master, mobiel: e.target.value });
							}}
							type="text"
							disabled={!editingPerson}
							value={master.mobiel}
						/>
						<label className={styles.label}>Telefoonnummer partner</label>
						<input
							className={styles.input}
							onChange={(e) => {
								setMaster({ ...master, mobiel_partner: e.target.value });
							}}
							type="text"
							disabled={!editingPerson}
							value={master.mobiel_partner}
						/>
						<label className={styles.label}>E-mail</label>
						<input
							className={styles.input}
							onChange={(e) => {
								setMaster({ ...master, emailadres: e.target.value });
							}}
							type="text"
							disabled={!editingPerson}
							value={master.emailadres}
						/>
					</form>{" "}
				</div>{" "}
				{!editingPerson ? (
					<button onClick={handleEditPersoon} className={styles.button}>
						Bewerk details
					</button>
				) : (
					<button onClick={handleStopPersoonEdit} className={styles.button}>
						Stop bewerken
					</button>
				)}{" "}
				{editingPerson ? (
					<div>
						<button
							onClick={savePersonChanges}
							disabled={!editingPerson}
							className={styles.button}
						>
							Wijzig naamgegevens
						</button>
					</div>
				) : null}
				<div className={styles.details}>
					<p className={styles.header}>Detailsgegevens persoon</p>
					<p>
						Note: gegevens geslacht kunnen (tijdelijk) niet worden aangepast
					</p>
					<div>
						<form className={styles.form}>
							<label className={styles.label}>Godsdienst</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({ ...personalia, godsdienst: e.target.value });
								}}
								type="text"
								disabled={!editingDetails}
								value={personalia.godsdienst}
							/>
							<label className={styles.label}>Geslacht</label>
							<select
								className={styles.input}
								id="geslacht"
								name="geslacht"
								disabled
								value={personalia.geslacht === "Man" ? "1" : "2"}
								onChange={(e) => {
									setPersonalia({ ...personalia, geslacht: e.target.value });
									console.log(e.target.value);
									console.log(personalia.geslacht);
								}}
							>
								<option value="Man">Man</option>
								<option value="Vrouw">Vrouw</option>
								<option value="Neutraal">Neutraal</option>
							</select>
							<label className={styles.label}>Geboorteplaats</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										geboorteplaats: e.target.value,
									});
								}}
								type="text"
								disabled={!editingDetails}
								value={personalia.geboorteplaats}
							/>
							<label className={styles.label}>Geboorteland</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										geboorteland: e.target.value,
									});
								}}
								type="text"
								disabled={!editingDetails}
								value={personalia.geboorteland}
							/>
							<label className={styles.label}>Geboortedatum</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										geboortedatum: e.target.value,
									});
								}}
								type="date"
								disabled={!editingDetails}
								value={personalia.geboortedatum}
							/>
							<label className={styles.label}>Land van herkomst</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										landherkomst: e.target.value,
									});
								}}
								type="text"
								disabled={!editingDetails}
								value={personalia.landherkomst}
							/>
							<label className={styles.label}>Taal</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({ ...personalia, taal: e.target.value });
								}}
								type="text"
								disabled={!editingDetails}
								value={personalia.taal}
							/>
							<label className={styles.label}>Verblijfsstatus</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										verblijfsstatus: e.target.value,
									});
								}}
								type="text"
								disabled={!editingDetails}
								value={personalia.verblijfsstatus}
							/>
							<label className={styles.label}>In NL sinds</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										in_nederland_sinds: e.target.value,
									});
								}}
								type="date"
								disabled={!editingDetails}
								value={personalia.in_nederland_sinds}
							/>
							<label className={styles.label}>In WbD sinds</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										in_wbd_sinds: e.target.value,
									});
								}}
								type="date"
								disabled={!editingDetails}
								value={personalia.in_wbd_sinds}
							/>
							<label className={styles.label}>
								Verblijfsvergunning geldig tot
							</label>
							<input
								className={styles.input}
								onChange={(e) => {
									setPersonalia({
										...personalia,
										verblijfgeldigheid: e.target.value,
									});
								}}
								type="date"
								disabled={!editingDetails}
								value={personalia.verblijfgeldigheid}
							/>
						</form>
					</div>
					{!editingDetails ? (
						<button className={styles.button} onClick={handleEditDetails}>
							Bewerk details
						</button>
					) : (
						<button className={styles.button} onClick={handleStopDetailsEdit}>
							Annuleren
						</button>
					)}
					{editingDetails ? (
						<div>
							<button
								onClick={saveDetailsChanges}
								disabled={!editingDetails}
								className={styles.button}
							>
								Wijzig detailgegevens
							</button>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default Personalia;
