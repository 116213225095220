import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const styles = {
	flex: "flex flex-row w-full",
	table: "min-w-full leading-normal mt-5",
	detail: "font-serif text-gray-700 hover:text-red-800 whitespace-no-wrap",
	tableheader:
		"px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-l font-semibold text-gray-600 uppercase tracking-wider",
	tablelines: "border border-green-600 mt-5",
	tableitem: "border border-green-600 mt-5 text-left",
	container: "basis-3/4 justify-center h-screen p-10",
	sidepicture: "basis-1/4 justify-center h-screen",
	image: "w-full h-full object-cover",
	header: "text-2xl font-bold text-green-600",
	tabledata:
		"px-2 py-2 border-b border-gray-200 bg-white text-sm bg-gray-100 hover:bg-gray-200",
	detail: "font-serif text-gray-700 hover:text-red-800 whitespace-no-wrap",
	button:
		"text-[10pt] border bg-green-600 hover:bg-green-700 w-full h-full text-white rounded-lg",
	searchfield: "border-2 border-green-600 rounded-lg p-2 m-2 w-96",
};

function Client_list(props) {
	const [client_list, setClientList] = useState([]);
	const [search, setSearch] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		axios({
			method: "GET",
			url: "/api/clientlist",
			headers: { Authorization: "Bearer " + props.token },
		})
			.then((res) => {
				setClientList(res.data);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					axios({
						method: "POST",
						url: "/api/logoutreact",
					});
					navigate("/login");
				}
			});
	}, []);

	const handleDoubleClick = (client) => {
		navigate("/spoorboekje/", { state: { from: client } });
	};

	return (
		<div className={styles.container}>
			<p className={styles.header}>Overzicht alle clienten op persoonsniveau</p>
			<div>
				<input
					placeholder="zoeken op clientnaam (achternaam)"
					className={styles.searchfield}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<table className={styles.table}>
					<thead className={styles.tableheader}>
						<tr>
							<th>Persoon Id</th>
							<th>Familienaam</th>
							<th>Clientnaam</th>
						</tr>
					</thead>
					<tbody className={styles.tablelines}>
						{!search
							? client_list.map((client) => (
									<tr className={styles.tableitem} key={client.id}>
										<td className={styles.tabledata}>
											<p
												className={styles.detail}
												onDoubleClick={() => handleDoubleClick(client)}
											>
												{client.id}
											</p>
										</td>
										<td className={styles.tabledata}>
											{" "}
											<p
												className={styles.detail}
												onDoubleClick={() => handleDoubleClick(client)}
											>
												{client.familienaam}
											</p>
										</td>
										<td className={styles.tabledata}>
											{" "}
											<p
												className={styles.detail}
												onDoubleClick={() => handleDoubleClick(client)}
											>
												{client.voornaam} {client.achternaam}
											</p>
										</td>
									</tr>
							  ))
							: client_list
									.filter((clt) =>
										clt.achternaam.toLowerCase().includes(search.toLowerCase())
									)
									.map((client) => (
										<tr className={styles.tableitem} key={client.id}>
											<td className={styles.tabledata}>
												<p
													className={styles.detail}
													onDoubleClick={() => handleDoubleClick(client)}
												>
													{client.id}
												</p>
											</td>
											<td className={styles.tabledata}>
												{" "}
												<p
													className={styles.detail}
													onDoubleClick={() => handleDoubleClick(client)}
												>
													{client.familienaam}
												</p>
											</td>
											<td className={styles.tabledata}>
												{" "}
												<p
													className={styles.detail}
													onDoubleClick={() => handleDoubleClick(client)}
												>
													{client.voornaam} {client.achternaam}
												</p>
											</td>
										</tr>
									))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Client_list;
